import { FC, useEffect, useRef, useState } from "react";
import { confetti } from "@tsparticles/confetti";
import {
  AlignItems,
  Box,
  Dialog,
  FontWeight,
  Glyph,
  Icon,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { parseAsBoolean, useQueryState } from "nuqs";

import { useCollectionsPermitted } from "../../common/hooks/useCollectionsPermitted";

import AnimatedLoadingText from "./AnimatedLoadingText";

import { i18nMarkTranslate, ToTranslate } from "src/components/i18n";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import CollectionsEnabledDialogImage from "src/assets/svg/collections-enabled-dialog.svg";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { LroEligibilityResponse } from "src/common/trackingEventAttributes";

// refresh creditor restriction status every 3s until undergoing_initial_onboarding_verifications is true
const CHECK_INTERVAL = 3000;

// close the dialog after 20s if the restriction does not become true
const CHECK_TIMEOUT = 20000;

export const CollectionsEnabledDialog: FC = () => {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE,
  });
  const { sendEvent } = useSegment();

  const [open, setOpen] = useQueryState(
    "collections_enabled_dialog",
    parseAsBoolean
  );
  const [intervalPassed, setIntervalPassed] = useState(false);

  const startTime = useRef(new Date().getTime());

  const {
    collectionsEnabled,
    checkingLowRisk,
    refreshCollectionsPermitted,
    collectionsEnabledTrackingAttribute,
  } = useCollectionsPermitted();

  const { status } = useVerificationStatus();

  useEffect(() => {
    if (status !== CreditorsVerificationStatus.Successful && isVariationOn) {
      setOpen(true);
      sendEvent(TrackingEvent.GET_STARTED_COLLECTIONS_ENABLED_DIALOG_OPENED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVariationOn, status]);

  useEffect(() => {
    if (!open) return;

    const interval = setInterval(() => {
      setIntervalPassed(true);
      refreshCollectionsPermitted();
    }, CHECK_INTERVAL);

    const timeout = setTimeout(() => {
      setOpen(null);

      const endTime = new Date().getTime();
      sendEvent(TrackingEvent.GET_STARTED_COLLECTIONS_ENABLED_DIALOG_CLOSED, {
        duration: (endTime - startTime.current) / 1000,
        reason: LroEligibilityResponse.TIMEOUT,
        ...collectionsEnabledTrackingAttribute,
      });

      clearInterval(interval);
    }, CHECK_TIMEOUT);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!intervalPassed) return;

    if (!checkingLowRisk) {
      setOpen(null);

      const sendDialogClosedEvent = () => {
        const endTime = new Date().getTime();
        sendEvent(TrackingEvent.GET_STARTED_COLLECTIONS_ENABLED_DIALOG_CLOSED, {
          duration: (endTime - startTime.current) / 1000,
          reason: LroEligibilityResponse.SUCCESS,
          ...collectionsEnabledTrackingAttribute,
        });
      };

      if (collectionsEnabled) {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        }).then(sendDialogClosedEvent);
      } else {
        sendDialogClosedEvent();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalPassed, checkingLowRisk]);

  return (
    <Dialog open={open ?? false}>
      <Box layout="flex" flexDirection="column" alignItems={AlignItems.Center}>
        <img
          src={CollectionsEnabledDialogImage}
          width={192}
          height={170}
          alt=""
        />

        <Space v={1.5} />

        <Text
          preset={TypePreset.Body_02}
          weight={FontWeight.SemiBold}
          textAlign={TextAlign.Center}
        >
          <ToTranslate>
            Time to say goodbye to late, manual payments
          </ToTranslate>
        </Text>

        <Separator spacing={[[1.5, 0]]} css={{ width: "100%" }} />

        <Box spaceBelow={0.25}>
          <Icon name={Glyph.Spinner} size="32px" />
        </Box>

        <AnimatedLoadingText
          strings={[
            i18nMarkTranslate("Creating your account"),
            i18nMarkTranslate("Building your dashboard"),
            i18nMarkTranslate("Verifying your details"),
            i18nMarkTranslate("Completing checks"),
          ]}
          time={5000}
        />
      </Box>
    </Dialog>
  );
};
