import {
  ColorPreset,
  Box,
  JustifyContent,
  Space,
  FontWeight,
} from "@gocardless/flux-react";

import { useAccountSetup } from "./useAccountSetup";
import AccountSetup from "./AccountSetup";
import { TopBanners } from "./Banners/TopBanners";
import NeedHelp from "./NeedHelp/NeedHelp";

import { Route } from "src/common/routing";
import { PrimaryPageLayout } from "src/components/layout";
import { PrimaryPageLayoutWidth } from "src/components/layout/PrimaryPageLayout";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { ReportingContextProvider } from "src/components/ui/Reporting/components/ReportingContextProvider";
import { Reporting } from "src/components/ui/Reporting";
import { useOrganisation } from "src/queries/organisation";
import { OutboundPaymentBalance } from "src/components/HomeOutboundPaymentBalance";
import { HomeOutboundAccountHeader } from "src/components/HomeOutboundAccountHeader";

const Home: React.FC = () => {
  const organisation = useOrganisation();
  const outboundPaymentsEnabled =
    organisation?.outbound_payments_enabled ?? false;

  const { isVariationOn: cpvVariantEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE,
  });
  const { isVariationOn: isPreactiveReportingEnabled } = useOptimizelyVariation(
    {
      flag: OptimizelyFlag.TURBO_GROWTH_PREACTIVE_MERCHANTS_REPORTING,
    }
  );

  const accountSetupProps = useAccountSetup();
  const needHelpProps = {
    getStartedType: accountSetupProps.getStartedType,
    kccVariantEnabled: accountSetupProps.kccVariantEnabled,
  };
  return (
    <PrimaryPageLayout
      bg={ColorPreset.BackgroundLight_02}
      highlightedMenuLink={Route.Home}
      maxWidth={PrimaryPageLayoutWidth.MEDIUM}
      gutterH={[1.5, 2, 3, 4]}
    >
      <TopBanners />
      <Box layout="flex" justifyContent={JustifyContent.Center}>
        <Box
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.Start}
          maxWidth={
            cpvVariantEnabled || isPreactiveReportingEnabled
              ? PrimaryPageLayoutWidth.MEDIUM
              : PrimaryPageLayoutWidth.NARROW
          }
        >
          {isPreactiveReportingEnabled ? (
            <>
              <ReportingContextProvider>
                <AccountSetup {...accountSetupProps} />
                <Reporting />
              </ReportingContextProvider>
              <Space v={4} />
            </>
          ) : (
            <>
              <AccountSetup {...accountSetupProps} />
              {outboundPaymentsEnabled && (
                <>
                  <HomeOutboundAccountHeader
                    size={[5, null, 7]}
                    weight={FontWeight.SemiBold}
                  />
                  <Space v={[2, null, 3]} />
                  <OutboundPaymentBalance />
                  <Space v={4} />
                </>
              )}
            </>
          )}

          <NeedHelp {...needHelpProps} />
        </Box>
      </Box>
    </PrimaryPageLayout>
  );
};

export default Home;
