import { useMemo } from "react";
import {
  Box,
  ButtonVariant,
  ColorPreset,
  Glyph,
  IconButton,
  P,
  PlainLink,
} from "@gocardless/flux-react";
import { PlanListResponseBody } from "@gocardless/api/dashboard/plan";
import { Trans } from "@lingui/macro";

import { useShareWithEvents } from "../../hooks/useShareWithEvents";
import { ListProps } from "../../../Home/types";

import { DashboardList } from "./component/DashboardList";
import { SubscriptionCount } from "./component/SubscriptionCount";

import { TrackingEvent } from "src/common/trackingEvents";
import { LinkBuilder } from "src/components/routing";
import { getRecurringPaymentDescription } from "src/libraries/subscription/utils/description";
import { ShareWrapper } from "src/components/payment-sharing/ShareWrapper";
import { Route } from "src/common/routing";

export interface TopSubscriptionTemplatesProps {
  entry?: PlanListResponseBody;
  isLoading: boolean;
  list: ListProps;
}
export const TopSubscriptionTemplates: React.FC<
  TopSubscriptionTemplatesProps
> = ({ entry, isLoading, list }) => {
  const onCopySubscription = useShareWithEvents({
    event: TrackingEvent.PLANS_SHARE_COMPLETED,
    dedupedEvent: TrackingEvent.PLANS_SHARE_COMPLETED_DEDUPED,
  });

  const items = useMemo(
    () =>
      entry?.plans?.map((plan) => ({
        id: plan.id,
        title: (
          <LinkBuilder
            route={Route.SubscriptionTemplate}
            routeParams={{ id: plan.id }}
          >
            {(result) => <PlainLink {...result}>{plan.name ?? ""}</PlainLink>}
          </LinkBuilder>
        ),

        subtitle: (
          <Box layout="flex" flexDirection="column">
            <P
              color={ColorPreset.TextOnLight_01}
              css={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {getRecurringPaymentDescription(plan)}
            </P>
            <P
              color={ColorPreset.TextOnLight_02}
              css={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <SubscriptionCount planId={plan.id ?? ""} />
            </P>
          </Box>
        ),
        actionIcon: (
          <ShareWrapper onClick={() => onCopySubscription(plan.url)}>
            {({ isActive, ...triggerProps }) => (
              <IconButton
                {...triggerProps}
                icon={Glyph.Link}
                label={<Trans id="share-link.copy-link">Copy link</Trans>}
                variant={
                  isActive
                    ? ButtonVariant.SecondaryOnLight
                    : ButtonVariant.TextOnLight
                }
              />
            )}
          </ShareWrapper>
        ),
      })),
    [entry?.plans, onCopySubscription]
  );

  return <DashboardList {...list} loading={isLoading} items={items} />;
};
