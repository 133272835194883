import {
  Box,
  ButtonVariant,
  FontWeight,
  Glyph,
  Interpose,
  Link,
  P,
  PlainButton,
  PlainLink,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { FirstPayoutDialogVariant } from "./types";
import {
  BackButton,
  CreatePaymentWithBackButton,
  ViewPaymentWithBackButton,
} from "./FirstPayoutButton";
import { BaseDialogProps } from "./FirstPayoutDialog";

import ExtendDiscountBox from "src/components/incentive-banner/ExtendDiscountBox";
import { Route, getRouteURL, routerPush } from "src/common/routing";

interface FirstPayoutConfigProps {
  onBackClick: () => void;
  hasCustomer: boolean;
  canExtendDiscount: boolean;
  appName?: string;
  openCreatePaymentDialog: () => void;
  openRewardsDialog: () => void;
  collectionsEnabled?: boolean;
}
const FirstPayoutConfig = ({
  onBackClick,
  appName,
  hasCustomer,
  canExtendDiscount,
  openCreatePaymentDialog,
  openRewardsDialog,
  collectionsEnabled,
}: FirstPayoutConfigProps): Record<
  FirstPayoutDialogVariant,
  Omit<BaseDialogProps, "open" | "toggleDialog">
> => {
  const createPaymentLink = (
    <PlainButton
      decoration="underline"
      onClick={
        hasCustomer
          ? () => openCreatePaymentDialog()
          : () => routerPush({ route: Route.SetupPayments })
      }
    >
      <Trans id="get-your-first-payout.create-payment-link">
        Create payment
      </Trans>
    </PlainButton>
  );
  const paymentPageLink = (
    <PlainLink
      href={getRouteURL({ route: Route.Payments, absolute: true })}
      decoration="underline"
    >
      <Trans id="get-your-first-payout.payment-page-link">payments page</Trans>
    </PlainLink>
  );
  const existingSoftwareLink = (
    <PlainLink
      href={getRouteURL({
        route: Route.WelcomeUsageSelection,
      })}
      target="_blank"
      rel="noreferrer"
      textDecoration="underline"
    >
      <Trans id="get-your-first-payout.existing-software-link">
        existing software
      </Trans>
    </PlainLink>
  );
  const apiLink = (
    <PlainLink
      href={getRouteURL({
        route: Route.Developers,
      })}
      target="_blank"
      rel="noreferrer"
      textDecoration="underline"
    >
      <Trans id="get-your-first-payout.api-link">API</Trans>
    </PlainLink>
  );
  const extendDiscountBox = canExtendDiscount ? (
    <Box spaceAbove={1.5}>
      <ExtendDiscountBox
        collectionsEnabled={collectionsEnabled}
        openRewardsDialog={openRewardsDialog}
      />
    </Box>
  ) : null;

  return {
    [FirstPayoutDialogVariant.Api]: {
      body: (
        <Interpose node={<Space v={1.5} />}>
          <Box>
            <Text preset={TypePreset.Body_02}>
              <Trans id="get-your-first-payout.api.description">
                Below are some helpful links to help you start taking payments.
                You’ll be able to see scheduled payments on the{" "}
                {paymentPageLink}
                before they are paid out.
              </Trans>
            </Text>
            {extendDiscountBox}
          </Box>

          <Link
            variant={ButtonVariant.Inline}
            rightIcon={Glyph.Export}
            href="https://developer.gocardless.com/"
            target="_blank"
          >
            <Text
              preset={TypePreset.Body_02}
              weight={FontWeight.SemiBold}
              decoration="underline"
            >
              <Trans id="get-your-first-payout.api.payment-with-api-link">
                Create payments using the GoCardless API
              </Trans>
            </Text>
          </Link>

          <PlainLink
            href={getRouteURL({
              route: Route.SetupPayments,
            })}
            decoration="underline"
            weight={FontWeight.SemiBold}
            preset={TypePreset.Body_02}
          >
            <Trans id="get-your-first-payout.api.payment-without-code-link">
              Create payments without code
            </Trans>
          </PlainLink>
        </Interpose>
      ),
      footer: <BackButton onBackClick={onBackClick} />,
    },
    [FirstPayoutDialogVariant.App]: {
      body: (
        <Interpose node={<Space v={1.5} />}>
          <Box>
            <Text preset={TypePreset.Body_02}>
              <Trans id="get-your-first-payout.app.description">
                As you’ve connected GoCardless to {appName}, we recommend
                setting up payments in {appName}. You can also create payments
                using the GoCardless dashboard but these won’t be synced with
                your software.
              </Trans>
            </Text>
            {extendDiscountBox}
          </Box>

          <Link
            variant={ButtonVariant.Inline}
            rightIcon={Glyph.Export}
            href="https://hub.gocardless.com/s/article/Introduction-to-partner-integrations"
            target="_blank"
          >
            <Text
              preset={TypePreset.Body_02}
              weight={FontWeight.SemiBold}
              decoration="underline"
            >
              <Trans id="get-your-first-payout.app.learn-more-about-app-link">
                Learn more about using GoCardless with partner apps.
              </Trans>
            </Text>
          </Link>

          <PlainLink
            href={getRouteURL({
              route: Route.SetupPayments,
            })}
            decoration="underline"
            weight={FontWeight.SemiBold}
            preset={TypePreset.Body_02}
          >
            <Trans id="get-your-first-payout.app.payment-with-dashboard-link">
              Create payments using the GoCardless dashboard
            </Trans>
          </PlainLink>
        </Interpose>
      ),
      footer: <BackButton onBackClick={onBackClick} />,
    },
    [FirstPayoutDialogVariant.DashboardNoPayment]: {
      body: (
        <>
          <P>
            <Trans id="get-your-first-payout.dashboard-no-payment.description">
              You haven’t got any scheduled payments yet, just click{" "}
              {createPaymentLink} to start accepting payments.
            </Trans>
          </P>
          <P>
            <Trans id="get-your-first-payout.dashboard-no-payment.description-alternative">
              Alternatively, you can automate payments with your{" "}
              {existingSoftwareLink} or use our {apiLink}.
            </Trans>
          </P>
          {extendDiscountBox}
        </>
      ),
      footer: (
        <CreatePaymentWithBackButton
          onCreatePaymentClick={() => {
            hasCustomer
              ? openCreatePaymentDialog()
              : routerPush({ route: Route.SetupPayments });
          }}
          onBackClick={onBackClick}
        />
      ),
    },
    [FirstPayoutDialogVariant.DashboardWithPayment]: {
      body: (
        <>
          <Trans id="get-your-first-payout.dashboard-with-payment.description">
            View your scheduled payments on the {paymentPageLink} before they
            are paid into your bank account.
          </Trans>
          {extendDiscountBox}
        </>
      ),
      footer: (
        <ViewPaymentWithBackButton
          onCreatePaymentClick={() => {
            hasCustomer
              ? openCreatePaymentDialog()
              : routerPush({ route: Route.SetupPayments });
          }}
          onBackClick={onBackClick}
        />
      ),
    },
    [FirstPayoutDialogVariant.DashboardWithPayout]: {
      body: (
        <Trans id="get-your-first-payout.dashboard-with-payout.description">
          View your upcoming payouts on the {paymentPageLink} before they are
          paid into your bank account.
        </Trans>
      ),
      footer: (
        <ViewPaymentWithBackButton
          onCreatePaymentClick={() => {
            hasCustomer
              ? openCreatePaymentDialog()
              : routerPush({ route: Route.SetupPayments });
          }}
          onBackClick={onBackClick}
        />
      ),
    },
  };
};

export default FirstPayoutConfig;
