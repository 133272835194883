import { useReportingMetric } from "@gocardless/api/dashboard/reporting";
import { ReportingTimeSeriesCurrency } from "@gocardless/api/dashboard/types";

import { useReportingContext } from "../ReportingContextProvider";
import { useReportingError } from "../../hooks/useReportingError";
import { useSetUpdatedAt } from "../../hooks/useSetUpdatedAt";

import { DataPoint, ReportingChartType } from "./utils";

import { castDateToString, castStringToDate } from "src/common/date-helper";
import { Currency } from "src/common/currencies";
import { useDefaultCreditor } from "src/queries/creditor";

interface UseFetchTimeSeriesDataOptions {
  chartType: ReportingChartType;
  startDate?: string;
  endDate?: string;
  currency?: Currency;
  shouldMakeRequest?: boolean;
}

export const useFetchTimeSeriesData = ({
  chartType,
  startDate,
  endDate,
  currency,
  shouldMakeRequest = true,
}: UseFetchTimeSeriesDataOptions) => {
  const creditor = useDefaultCreditor();
  const { startDateFilter, endDateFilter, selectedCurrency } =
    useReportingContext();

  const {
    data: reportingData,
    isValidating,
    isLoading,
    error,
    key,
  } = useReportingMetric(
    chartType,
    {
      creditor: creditor?.id ?? "",
      start_date: castStringToDate(startDate ?? startDateFilter),
      end_date: castStringToDate(endDate ?? endDateFilter),
    },
    shouldMakeRequest
  );

  const updatedAt = castDateToString(reportingData?.updated_at);

  useReportingError(error, key);
  useSetUpdatedAt(updatedAt);

  if (reportingData?.[chartType]) {
    const metricData = reportingData?.[
      chartType
    ] as ReportingTimeSeriesCurrency;
    const metricDataPoints = metricData[
      selectedCurrency ?? currency
    ] as DataPoint[];

    if (metricDataPoints) {
      const dataPointsSum = metricDataPoints.reduce(
        (acc, dataPoint) => acc + dataPoint.value,
        0
      );

      return {
        data: metricDataPoints,
        isLoading,
        isValidating,
        dataPointsSum:
          chartType === "failed_payment_attempts"
            ? dataPointsSum
            : dataPointsSum / 100,
      };
    }
  }

  return {
    data: [],
    isLoading,
    isValidating,
    dataPointsSum: 0,
  };
};
