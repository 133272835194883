import { useMemo } from "react";
import {
  ButtonVariant,
  Dropdown,
  Glyph,
  IconButton,
  Menu,
  MenuDensity,
  MenuRole,
  P,
  PlainLink,
} from "@gocardless/flux-react";
import { CustomerListResponseBody } from "@gocardless/api/dashboard/customer";
import { useRouter } from "next/router";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { ListProps } from "../../../Home/types";

import { DashboardList } from "./component/DashboardList";

import { Route } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import {
  ADD_TO_PLAN_TAB,
  CREATE_SUBSCRIPTION_TAB,
} from "src/components/routes/RequestPayment/RecurringPayment/ExistingCustomerCreate/ExistingCustomerCreate";
import { getCustomerDisplayName } from "src/libraries/customer/utils/customerHelpers";

const useCustomerActionRoutes = () => {
  const { i18n } = useLingui();

  const customerActionRoutes = [
    {
      id: "add_to_subscription_template",
      label: i18n._(
        t({
          id: "homepage.quickactions.customer-createsubscriptio",
          message: "Add to subscription template",
        })
      ),
      route: Route.RecurringPaymentCreate,
      event: TrackingEvent.CUSTOMERS_ADD_TO_PLAN_CTA_CLICKED,
      queryParams: { tab: ADD_TO_PLAN_TAB },
    },
    {
      id: "create_one-off_payment",
      label: i18n._(
        t({
          id: "homepage.quickactions.customer-createpaylink",
          message: "Create one-off payment",
        })
      ),
      route: Route.OneOffPaymentCreate,
      event: TrackingEvent.CUSTOMERS_CREATE_PAYMENT_CTA_CLICKED,
    },
    {
      id: "create_subscription",
      label: i18n._(
        t({
          id: "homepage.quickactions.customer-createsubscription",
          message: "Create subscription",
        })
      ),
      route: Route.RecurringPaymentCreate,
      event: TrackingEvent.CUSTOMERS_CREATE_SUBSCRIPTION_CTA_CLICKED,
      queryParams: { tab: CREATE_SUBSCRIPTION_TAB },
    },
    {
      id: "create_instalment_payment",
      label: i18n._(
        t({
          id: "homepage.quickactions.customer-createinstalmentpayment",
          message: "Create instalment payment",
        })
      ),
      route: Route.InstalmentScheduleCreate,
      event: TrackingEvent.CUSTOMERS_CREATE_INSTALMENT_SCHEDULE_CTA_CLICKED,
    },
  ];

  return customerActionRoutes;
};

export interface LastCustomersProps {
  entry?: CustomerListResponseBody;
  isLoading: boolean;
  list: ListProps;
}
export const LastCustomers: React.FC<LastCustomersProps> = ({
  entry,
  isLoading,
  list,
}) => {
  const customerActionRoutes = useCustomerActionRoutes();
  const router = useRouter();
  const { sendEvent } = useSegment();

  const items = useMemo(
    () =>
      entry?.customers?.map((customer) => ({
        id: customer.id,
        title: (
          <LinkBuilder route={Route.Customer} routeParams={{ id: customer.id }}>
            {(result) => (
              <PlainLink {...result}>
                {getCustomerDisplayName(customer)}
              </PlainLink>
            )}
          </LinkBuilder>
        ),
        subtitle: (
          <P
            css={{
              textOverflow: "ellipsis",
              overflowX: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {customer.email}
          </P>
        ),
        actionIcon: (
          <Dropdown
            controls="sample-content"
            trigger={(props) => (
              <IconButton
                {...props}
                label="more"
                icon={Glyph.MoreHorizontal}
                variant={ButtonVariant.TextOnLight}
              />
            )}
          >
            <Menu.Root
              id="customer-actions"
              role={MenuRole.Menu}
              density={MenuDensity.Compact}
            >
              {customerActionRoutes.map((actionMenuItem) => (
                <LinkBuilder
                  key={actionMenuItem.id}
                  route={actionMenuItem.route}
                  queryParams={{
                    customer_id: customer.id,
                    ...(actionMenuItem.queryParams || {}),
                  }}
                  onClick={() => {
                    sendEvent(actionMenuItem.event, {
                      page: router.pathname,
                      customer_id: customer.id,
                    });
                  }}
                >
                  {({ ref, ...result }) => (
                    <Menu.Link {...result}>{actionMenuItem.label}</Menu.Link>
                  )}
                </LinkBuilder>
              ))}
            </Menu.Root>
          </Dropdown>
        ),
      })),
    [customerActionRoutes, entry?.customers, router.pathname, sendEvent]
  );

  return <DashboardList {...list} loading={isLoading} items={items} />;
};
