import {
  Box,
  ButtonVariant,
  Color,
  DynamicList,
  DynamicListItem,
  Glyph,
  IconButton,
  JustifyContent,
  Overflow,
  Text,
  PlainLink,
  Space,
  XYGrid,
  TypePreset,
  FontWeight,
  ColorPreset,
  HoverEffect,
  Breakpoint,
  H4,
  TextAlign,
  useTheme,
} from "@gocardless/flux-react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";

import { ListProps, MAX_LISTITEMS } from "../../../../Home/types";

import { EmptyListState } from "./EmptyListState";

import { LinkBuilder } from "src/components/routing";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";

export enum appearanceType {
  link = "link",
  plainLink = "plainLink",
}

interface DashboardListProps extends ListProps {
  loading?: boolean;
  items?: {
    id?: string;
    url?: string | null;
    title: string | React.ReactNode;
    subtitle: React.ReactNode;
    actionIcon: React.ReactNode;
  }[];
}

export const DashboardList: React.FC<DashboardListProps> = ({
  loading,
  items = [],
  buttonName,
  route,
  routeParams,
  queryParams,
  title,
  event,
  emptyMessage,
}) => {
  const { theme } = useTheme();
  const isLargeBreakpoint = useIsBreakpoint({ min: Breakpoint.Md });
  const { sendEvent } = useSegment();
  const router = useRouter();
  const isEmptyList = !loading && !items.length;
  const placeholderItem = {
    id: `loading`,
    title: "",
    subtitle: <EmptyListState />,
    actionIcon: (
      <IconButton
        disabled={true}
        icon={Glyph.Link}
        label={<Trans id="share-link.copy-link">Copy link</Trans>}
        variant={ButtonVariant.TextOnLight}
      />
    ),
  };
  const listItems = isEmptyList ? [placeholderItem] : items;
  const onClick = () => {
    if (event) {
      sendEvent(event as TrackingEvent, {
        page: router.pathname,
      });
    }
  };
  return (
    <>
      {isLargeBreakpoint || isEmptyList ? (
        <H4
          preset={TypePreset.Heading_01}
          color={ColorPreset.TextOnLight_01}
          textAlign={TextAlign.Left}
          spaceBelow={2}
        >
          {isEmptyList ? emptyMessage : title}
        </H4>
      ) : null}
      <Box
        layout="flex"
        flexDirection="column"
        flexGrow={1}
        height="100%"
        width="100%"
        justifyContent={JustifyContent.SpaceBetween}
      >
        <Box>
          <DynamicList
            loading={loading}
            placeholderCount={MAX_LISTITEMS}
            border="none"
          >
            {listItems?.map((item) => (
              <DynamicListItem
                id={item.id ?? ""}
                key={item.id}
                primaryAction={{
                  children: (
                    <Box
                      overflowX={Overflow.Hidden}
                      css={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Text
                        weight={FontWeight.SemiBold}
                        color={ColorPreset.TextOnLight_01}
                      >
                        {item.title}
                      </Text>
                    </Box>
                  ),
                }}
                moreActions={item.actionIcon}
              >
                <Box
                  layout="flex"
                  flexDirection="column"
                  maxWidth={[
                    `calc(100vw - ${theme.spacing(12)})`,
                    "unset",
                    null,
                  ]}
                >
                  {item.subtitle}
                </Box>
              </DynamicListItem>
            ))}
          </DynamicList>
        </Box>

        {!isEmptyList ? (
          <XYGrid rowGap={1}>
            <Space v={0} />
            <LinkBuilder
              key={route}
              routeParams={routeParams}
              queryParams={queryParams}
              route={route}
              onClick={onClick}
            >
              {({ ref, ...result }) => (
                <PlainLink
                  {...result}
                  effect={HoverEffect.TextDecoration}
                  preset={TypePreset.Body_01}
                  color={Color.Greystone_1400}
                  weight={FontWeight.SemiBold}
                  textDecoration="underline"
                  css={{ width: "fit-content" }}
                >
                  {buttonName}
                </PlainLink>
              )}
            </LinkBuilder>
          </XYGrid>
        ) : null}
      </Box>
    </>
  );
};
