import { FC, MouseEventHandler, useEffect, useState } from "react";
import {
  ColorScheme,
  Dialog,
  DialogProps,
  FontWeight,
  Interpose,
  P,
  ProgressBar,
  ProgressBarColor,
  ProgressBarVariant,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { Checklist } from "./Checklist";
import { progressBarStyle } from "./KccChecklistDialog.styles";
import { MoveCustomersDialog } from "./MoveCustomersDialog";
import { AcceptPaymentsDialog } from "./AcceptPaymentsDialog";
import { BookSessionDialog } from "./BookSessionDialog";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { Route, routerPush } from "src/common/routing";

interface KccChecklistDialogProps extends Pick<DialogProps, "open"> {
  toggleDialog: () => void;
  openCreatePaymentDialog: () => void;
  stepsCompleted: {
    brandExperience: boolean;
    collectFirstPayment: boolean;
    bookSession: boolean;
    collectThreePayments: boolean;
    acceptPayments: boolean;
    moveCustomers: boolean;
    enableTwoFactorAuth: boolean;
  };
  markStepCompleted: (
    step: "bookSession" | "acceptPayments" | "moveCustomers"
  ) => void;
  hasMandates: boolean;
}

export const KccChecklistDialog: FC<KccChecklistDialogProps> = ({
  open,
  toggleDialog,
  openCreatePaymentDialog,
  stepsCompleted,
  markStepCompleted,
  hasMandates,
}) => {
  const { theme } = useTheme();
  const { sendEvent, sendEventPromise } = useSegment();

  const [openDialog, setOpenDialog] = useState<
    "acceptPayments" | "bookSession" | "moveCustomers" | null
  >(null);

  const countSteps = Object.keys(stepsCompleted).length;

  const countStepsCompleted = Object.entries(stepsCompleted).filter(
    ([, value]) => value
  ).length;

  const handleCreatePaymentClick: MouseEventHandler<HTMLButtonElement> =
    function handleCreatePaymentClick() {
      if (hasMandates) {
        openCreatePaymentDialog();
      } else {
        routerPush({ route: Route.SetupPayments });
      }
    };

  useEffect(() => {
    if (open) {
      sendEvent(
        TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_DIALOG_OPENED,
        {
          items: {
            accept_payments: stepsCompleted.acceptPayments,
            book_session: stepsCompleted.bookSession,
            brand_experience: stepsCompleted.brandExperience,
            collect_first_payment: stepsCompleted.collectFirstPayment,
            collect_three_payments: stepsCompleted.collectThreePayments,
            enable_two_factor_auth: stepsCompleted.enableTwoFactorAuth,
            move_customers: stepsCompleted.moveCustomers,
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog
        as="div"
        open={open}
        header={{
          content: (
            <Text weight={FontWeight.SemiBold}>
              <Trans>Get paid effortlessly</Trans>
            </Text>
          ),
        }}
        closeAction={{
          label: "Close",
          onClose: () => {
            if (openDialog) return;
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_DIALOG_CLOSED
            );
            toggleDialog();
          },
        }}
      >
        <Interpose node={<Space v={2} />}>
          <P>
            <Trans>
              Use the checklist below to get fully set up so you&apos;re ready
              to get the most value out of GoCardless.
            </Trans>
          </P>

          <ProgressBar
            label={null}
            max={countSteps}
            value={countStepsCompleted}
            variant={ProgressBarVariant.Solid}
            color={ProgressBarColor.Info}
            colorScheme={ColorScheme.OnLight}
            css={[progressBarStyle(theme)]}
          />

          <Checklist>
            <Checklist.Item
              completed={stepsCompleted.brandExperience}
              onClick={async () => {
                await sendEventPromise(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "brand_your_experience",
                    completed: stepsCompleted.brandExperience,
                  }
                );
                routerPush({ route: Route.Brand });
              }}
            >
              <Trans>Brand your experience</Trans>
            </Checklist.Item>

            <Checklist.Item
              completed={stepsCompleted.collectFirstPayment}
              onClick={async (e) => {
                await sendEventPromise(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "collect_first_payment",
                    completed: stepsCompleted.collectFirstPayment,
                  }
                );
                handleCreatePaymentClick(e);
              }}
            >
              <Trans>Collect your first payment</Trans>
            </Checklist.Item>

            <Checklist.Item
              completed={stepsCompleted.bookSession}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "book_session",
                    completed: stepsCompleted.bookSession,
                  }
                );
                setOpenDialog("bookSession");
              }}
              onCompleteClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_DISMISSED,
                  {
                    target: "book_session",
                  }
                );
                markStepCompleted("bookSession");
              }}
            >
              <Trans>Book a 1-2-1 session with our onboarding experts</Trans>
            </Checklist.Item>

            <Checklist.Item
              completed={stepsCompleted.collectThreePayments}
              onClick={async (e) => {
                await sendEventPromise(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "collect_third_payment",
                    completed: stepsCompleted.collectThreePayments,
                  }
                );
                handleCreatePaymentClick(e);
              }}
            >
              <Trans>Collect your first three payments</Trans>
            </Checklist.Item>

            <Checklist.Item
              completed={stepsCompleted.acceptPayments}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "accept_payments",
                    completed: stepsCompleted.acceptPayments,
                  }
                );
                setOpenDialog("acceptPayments");
              }}
              onCompleteClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_DISMISSED,
                  {
                    target: "accept_payments",
                  }
                );
                markStepCompleted("acceptPayments");
              }}
            >
              <Trans>Accept payments on your website</Trans>
            </Checklist.Item>

            <Checklist.Item
              completed={stepsCompleted.moveCustomers}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "move_customers",
                    completed: stepsCompleted.moveCustomers,
                  }
                );
                setOpenDialog("moveCustomers");
              }}
              onCompleteClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_DISMISSED,
                  {
                    target: "move_customers",
                  }
                );
                markStepCompleted("moveCustomers");
              }}
            >
              <Trans>Move your existing customers to GoCardless</Trans>
            </Checklist.Item>

            <Checklist.Item
              completed={stepsCompleted.enableTwoFactorAuth}
              onClick={async () => {
                await sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_EXTENDED_CHECKLIST_ITEM_CLICKED,
                  {
                    target: "enable_two_factor_auth",
                    completed: stepsCompleted.enableTwoFactorAuth,
                  }
                );
                routerPush({ route: Route.MFASetup });
              }}
            >
              <Trans>Enable two-step authentication</Trans>
            </Checklist.Item>
          </Checklist>
        </Interpose>
      </Dialog>

      <AcceptPaymentsDialog
        open={openDialog === "acceptPayments"}
        onClose={() => setOpenDialog(null)}
      />

      <BookSessionDialog
        open={openDialog === "bookSession"}
        onClose={() => setOpenDialog(null)}
      />

      <MoveCustomersDialog
        open={openDialog === "moveCustomers"}
        onClose={() => setOpenDialog(null)}
      />
    </>
  );
};
