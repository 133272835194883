import { Trans } from "@lingui/macro";
import { NextPage } from "next";
import Head from "next/head";
import { useEffect } from "react";
import { useRouter } from "next/router";

import AuthWrapper from "src/components/authentication";
import { Home } from "src/components/routes/Home/Home";
import SetupPaymentsHome from "src/components/routes/SetupPayments/home";
import { SetupPaymentsEnabled } from "src/components/routes/SetupPayments/SetupPaymentsEnabled";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";
import { Route, getRouteURL } from "src/common/routing";

const HomePage: NextPage<{}> = () => {
  const { organisation, organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;
  const router = useRouter();

  useEffect(() => {
    if (isPaymentProvider) {
      router.push(getRouteURL({ route: Route.Creditors }));
    }
  }, [isPaymentProvider, router]);

  return (
    <AuthWrapper>
      <Head>
        <Trans id="page.home-title">Home • GoCardless</Trans>
      </Head>
      {organisation === undefined || isPaymentProvider ? null : (
        <SetupPaymentsEnabled defaultNode={<Home />}>
          <SetupPaymentsHome />
        </SetupPaymentsEnabled>
      )}
    </AuthWrapper>
  );
};

export default HomePage;
