import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  DateRangePicker,
  Glyph,
  H2,
  Icon,
  Interpose,
  JustifyContent,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
  TypeScale,
  Visibility,
  XYGrid,
  useTheme,
  Tooltip,
  P,
} from "@gocardless/flux-react";
import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  today,
} from "@internationalized/date";
import { mutate } from "swr";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import { OutboundPaymentBalanceType } from "../../HomeOutboundPaymentBalance/OutboundPaymentBalanceType";
import { HomeCollectionOverviewHeader } from "../../HomeCollectionOverviewHeader";

import { useReportingContext } from "./components/ReportingContextProvider";
import { ChartWidget } from "./components/ChartWidget/ChartWidget";
import { DateRangeMenu } from "./components/DateRangeMenu/DateRangeMenu";
import BetaWidget from "./components/BetaWidget/BetaWidget";
import { CollectionDataWidget } from "./components/CollectionDataWidget/CollectionDataWidget";
import CollectionCurrencyMenu from "./components/CollectionCurrencyMenu/CollectionCurrencyMenu";
import {
  accountHealthSectionDataWidgetTypes,
  collectionSectionDataWidgetTypes,
} from "./components/CollectionDataWidget/utils";
import { ErrorBanner } from "./components/ErrorBanner/ErrorBanner";
import { getMostRecentDate } from "./utils/common";
import {
  betaWidgetContainerLargeBreakpointStyle,
  betaWidgetContainerMediumBreakpointStyle,
  betaWidgetContainerSmallBreakpointStyle,
  chartWidgetContainerStyle,
  mobileSeparatorStyle,
} from "./utils/style";
import { useCollectionCurrencies } from "./hooks/useCollectionCurrencies";
import { OutboundDataWidget } from "./components/OutboundDataWidget/OutboundDataWidget";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";
import { HomeOutboundAccountHeader } from "src/components/HomeOutboundAccountHeader";
import { useOrganisation } from "src/queries/organisation";
import { useI18nLocale } from "src/hooks/useI18nLocale";
import { LastUpdatedText } from "src/components/routes/Home/components/LastUpdatedText";

export const Reporting = () => {
  const {
    fx_payout_currency: fxPayoutCurrency,
    outbound_payments_enabled: outboundPaymentsEnabled,
  } = useOrganisation() ?? {};

  const { collectionCurrencies } = useCollectionCurrencies();

  const { theme } = useTheme();
  const i18nLocale = useI18nLocale();
  const {
    setStartDateFilter,
    startDateFilter,
    endDateFilter,
    setEndDateFilter,
    failedRequestKeys,
    setFailedRequestKeys,
    updatedAtDates,
    isChartWidgetLoading,
  } = useReportingContext();

  const { i18n } = useLingui();

  const handleDatePickerChange = (date: CalendarDate[]) => {
    if (!date.length) return;
    const startDateObj = date[0] as CalendarDate;
    const endDateObj = date[1] as CalendarDate;
    const startDate = new CalendarDate(
      startDateObj.year,
      startDateObj.month,
      startDateObj.day
    ).toString();
    const endDate = new CalendarDate(
      endDateObj.year,
      endDateObj.month,
      endDateObj.day
    ).toString();
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  const handleOnReload = () => {
    mutate(
      (key) => typeof key === "string" && failedRequestKeys.includes(key),
      undefined,
      { revalidate: true }
    );

    setFailedRequestKeys([]);
  };

  const selectedDateRange = [
    parseDate(startDateFilter),
    parseDate(endDateFilter),
  ];

  const lastUpdatedDate = getMostRecentDate(updatedAtDates);
  const yesterday = today(getLocalTimeZone()).subtract({ days: 1 });

  const showMultiCurrencyLayout = collectionCurrencies?.length > 1;

  const templateAreas = outboundPaymentsEnabled
    ? [
        `
        "outbound"
        "spacer"
        "collection"
        "accountHealth"
        "overview"
        `,
        `
        ". ."
        "outbound outbound"
        "spacer spacer"
        "collection collection"
        "accountHealth accountHealth"
        "overview overview"
        `,
        null,
        `
        ". . ."
        "outbound outbound outbound"
        "spacer spacer spacer"
        "collection collection collection"
        "overview overview accountHealth"
        `,
      ]
    : [
        `
        "collection"
        "accountHealth"
        "."
        "overview"
        `,
        `
        "collection collection"
        "accountHealth accountHealth"
        ". ."
        "overview overview"
        `,
        null,
        `
        "collection collection collection"
        ". . ."
        "overview overview accountHealth"
        `,
      ];
  return (
    <Box>
      {!!failedRequestKeys.length && (
        <>
          <ErrorBanner onReload={handleOnReload} />
          <Space v={3} />
        </>
      )}

      <XYGrid
        columnGap={2}
        rowGap={2}
        templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        templateAreas={templateAreas}
      >
        {outboundPaymentsEnabled && (
          <Box gridArea="outbound">
            <Box spaceBelow={1.5}>
              <HomeOutboundAccountHeader />
            </Box>

            <Box
              bg={[Color.White, Color.Brownstone_50]}
              borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
              borderRadius={[1, 0]}
              borderWidth={[1, 0]}
              gutterH={[1.5, 0]}
              gutterV={[1.5, 0]}
            >
              <XYGrid
                columnGap={[0, 1.5, 2]}
                rowGap={[0, 1.5, 2]}
                templateColumns={[
                  "1fr",
                  "repeat(2, 1fr)",
                  null,
                  "repeat(3, 1fr)",
                ]}
              >
                <Interpose
                  node={
                    <Separator
                      align="center"
                      css={mobileSeparatorStyle(theme)}
                    />
                  }
                  trailing
                >
                  {[
                    OutboundPaymentBalanceType.Current,
                    OutboundPaymentBalanceType.Available,
                    OutboundPaymentBalanceType.Scheduled,
                  ].map((dataWidgetType) => (
                    <OutboundDataWidget
                      key={dataWidgetType}
                      type={dataWidgetType}
                    />
                  ))}
                </Interpose>
              </XYGrid>
            </Box>
          </Box>
        )}

        {outboundPaymentsEnabled && (
          <Box gridArea="spacer">
            <Separator />
          </Box>
        )}

        <Box gridArea="collection">
          <Box
            layout="flex"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.SpaceBetween}
            spaceBelow={1.5}
          >
            {outboundPaymentsEnabled ? (
              <HomeCollectionOverviewHeader />
            ) : (
              <HomeSectionHeader>
                <Trans>Today</Trans>
              </HomeSectionHeader>
            )}

            <Box layout="flex" alignItems={AlignItems.Center}>
              {showMultiCurrencyLayout && (
                <CollectionCurrencyMenu
                  currencies={collectionCurrencies}
                  fxPayoutCurrency={fxPayoutCurrency}
                />
              )}
            </Box>
          </Box>

          <Box
            bg={[Color.White, Color.Brownstone_50]}
            borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
            borderRadius={[1, 0]}
            borderWidth={[1, 0]}
            gutterH={[1.5, 0]}
            gutterV={[1.5, 0]}
          >
            <XYGrid
              columnGap={[0, 1.5, 2]}
              rowGap={[0, 1.5, 2]}
              templateColumns={[
                "1fr",
                "repeat(2, 1fr)",
                null,
                "repeat(3, 1fr)",
              ]}
            >
              <Interpose
                node={
                  <Separator align="center" css={mobileSeparatorStyle(theme)} />
                }
                trailing
              >
                {collectionSectionDataWidgetTypes.map((dataWidgetType) => (
                  <CollectionDataWidget
                    key={dataWidgetType}
                    type={dataWidgetType}
                  />
                ))}
              </Interpose>

              <Box css={betaWidgetContainerMediumBreakpointStyle(theme)}>
                <BetaWidget />
              </Box>
            </XYGrid>
          </Box>
        </Box>

        {!outboundPaymentsEnabled && (
          <HomeSectionHeader>
            <Trans>Overview</Trans>
          </HomeSectionHeader>
        )}

        <Box gridArea="overview" layout="flex" flexDirection="column">
          <Box spaceBelow={[1, 2]} layout="flex" alignItems={AlignItems.Center}>
            <Interpose node={<Space h={[1, 2]} layout="inline" />} trailing>
              <DateRangeMenu />
              <Box layout="flex" alignItems={AlignItems.Center}>
                <DateRangePicker
                  aria-label={i18n._(
                    t({
                      message: "Select custom chart date range",
                    })
                  )}
                  disabled={isChartWidgetLoading}
                  i18n={i18nLocale}
                  format="dd-mm-yyyy"
                  value={selectedDateRange}
                  onChange={handleDatePickerChange}
                  minDate={yesterday.subtract({ months: 3 })}
                  maxDate={yesterday}
                  triggerButton={{
                    style: {
                      padding: 0,
                      "&:hover": {
                        background: Color.Transparent,
                        textDecoration: "underline",
                      },
                      "&:focus": {
                        background: Color.Transparent,
                      },
                    },
                    children: (
                      <>
                        <Text preset={TypePreset.Heading_01}>
                          <Trans>Dates</Trans>
                        </Text>
                        <Space layout="inline" h={0.5} />
                        <Icon name={Glyph.Calendar} size="12px" />
                      </>
                    ),
                  }}
                />
              </Box>
            </Interpose>

            {lastUpdatedDate && (
              <>
                <Visibility visible={["none", null, "block"]}>
                  <Space h={1} layout="inline" />
                  <Text size={TypeScale.Size_01}>
                    <LastUpdatedText dateTimeString={lastUpdatedDate} />
                  </Text>
                  <Space layout="inline" h={0.75} />
                  <Tooltip
                    positionStrategy="absolute"
                    message={
                      <>
                        <P spaceBelow={1}>
                          <Trans>
                            GoCardless will refresh all data shown on a daily
                            basis just after midday (approximately 13:30 UTC).
                          </Trans>
                        </P>
                        <P>
                          <Trans>
                            This will mean data accuracy will vary depending on
                            when the homepage is viewed.
                          </Trans>
                        </P>
                      </>
                    }
                    triggeredBy="hover"
                  >
                    <Trans>
                      Information about reporting metric refresh rates
                    </Trans>
                  </Tooltip>
                </Visibility>
              </>
            )}
          </Box>

          <Box css={betaWidgetContainerSmallBreakpointStyle(theme)}>
            <BetaWidget />
          </Box>

          <Box css={chartWidgetContainerStyle(theme)} height="100%">
            <ChartWidget />
          </Box>
        </Box>

        <Box gridArea="accountHealth" layout="flex" flexDirection="column">
          <H2
            preset={TypePreset.Heading_04}
            textAlign={TextAlign.Left}
            spaceBelow={1.5}
          >
            <Trans>Account health</Trans>
          </H2>

          <Box
            bg={[Color.White, Color.Brownstone_50]}
            borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
            borderRadius={[1, 0]}
            borderWidth={[1, 0]}
            gutterH={[1.5, 0]}
            gutterV={[1.5, 0]}
            flexGrow={1}
          >
            <XYGrid
              columnGap={[0, 1.5, 2]}
              rowGap={[0, 1.5, 2]}
              templateColumns={["1fr", "repeat(2, 1fr)", null, "1fr"]}
              height="100%"
            >
              <Interpose
                node={
                  <Separator align="center" css={mobileSeparatorStyle(theme)} />
                }
                trailing
              >
                {accountHealthSectionDataWidgetTypes.map((dataWidgetType) => (
                  <CollectionDataWidget
                    key={dataWidgetType}
                    type={dataWidgetType}
                  />
                ))}
              </Interpose>
              <Box css={betaWidgetContainerLargeBreakpointStyle(theme)}>
                <BetaWidget />
              </Box>
            </XYGrid>
          </Box>
        </Box>
      </XYGrid>
    </Box>
  );
};
