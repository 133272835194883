import { Dialog, FontWeight, Text, TypePreset } from "@gocardless/flux-react";
import { useEffect } from "react";
import { Trans } from "@lingui/macro";

import { FirstPayoutDialogVariant } from "./types";
import FirstPayoutConfig from "./FirstPayoutConfig";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export interface BaseDialogProps {
  open: boolean;
  toggleDialog: () => void;
  body: React.ReactElement;
  footer: React.ReactElement;
  collectionsEnabled?: boolean;
}
interface FirstPayoutProps {
  variant: FirstPayoutDialogVariant;
  open: boolean;
  toggleDialog: () => void;
  hasCustomer: boolean;
  canExtendDiscount: boolean;
  appName: string;
  openCreatePaymentDialog: () => void;
  openRewardsDialog: () => void;
  collectionsEnabled?: boolean;
}

const BaseDialog: React.FC<BaseDialogProps> = ({
  open,
  toggleDialog,
  body,
  footer,
  collectionsEnabled,
}) => (
  <Dialog
    as="div"
    open={open}
    header={
      <Text preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
        {collectionsEnabled ? (
          <Trans id="get-your-first-payout.title.collections-enabled">
            Collect your first payment
          </Trans>
        ) : (
          <Trans id="get-your-first-payout.title">Get your first payout</Trans>
        )}
      </Text>
    }
    footer={footer}
    closeAction={{ label: "Close", onClose: toggleDialog }}
  >
    {body}
  </Dialog>
);

const FirstPayoutDialog: React.FC<FirstPayoutProps> = ({
  open,
  toggleDialog,
  variant,
  appName,
  hasCustomer,
  canExtendDiscount,
  openCreatePaymentDialog,
  openRewardsDialog,
  collectionsEnabled,
}) => {
  const { sendEvent } = useSegment();

  useEffect(() => {
    if (open) {
      sendEvent(TrackingEvent.GET_STARTED_PAYOUT_DIALOG_VIEWED);
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const props = FirstPayoutConfig({
    onBackClick: toggleDialog,
    appName,
    hasCustomer,
    canExtendDiscount,
    openCreatePaymentDialog,
    openRewardsDialog,
    collectionsEnabled,
  })[variant];

  return (
    <BaseDialog
      open={open}
      toggleDialog={toggleDialog}
      collectionsEnabled={collectionsEnabled}
      {...props}
    />
  );
};
export default FirstPayoutDialog;
