import { useReportingMetric } from "@gocardless/api/dashboard/reporting";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Box,
  Color,
  ColorPreset,
  FontWeight,
  H3,
  P,
  PlainLink,
  Shimmer,
  Space,
  Text,
  Tooltip,
  TypePreset,
  TypographyStyleProps,
} from "@gocardless/flux-react";

import { useReportingContext } from "../ReportingContextProvider";
import { useReportingError } from "../../hooks/useReportingError";
import { useSetUpdatedAt } from "../../hooks/useSetUpdatedAt";

import {
  CollectionDataWidgetType,
  getDataWidgetButtonEvent,
  getDataWidgetTooltipEvent,
  useCollectionDataWidgetTypesMap,
} from "./utils";

import { formatCurrencyAndSplit } from "src/utils/formatCurrencyAndSplit";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useDefaultCreditorId } from "src/queries/organisation";
import { castDateToString } from "src/common/date-helper";
import { LinkBuilder } from "src/components/routing";

export interface CollectionDataWidgetProps {
  type: CollectionDataWidgetType;
}

export const CollectionDataWidget: React.FC<CollectionDataWidgetProps> = ({
  type,
}) => {
  const { selectedCurrency } = useReportingContext();

  const defaultCreditorId = useDefaultCreditorId();

  const { data, error, isLoading, key } = useReportingMetric(type, {
    creditor: defaultCreditorId ?? "",
  });

  const { i18n } = useLingui();
  const { sendEvent } = useSegment();

  const updatedAt = castDateToString(data?.updated_at);

  useReportingError(error, key);
  useSetUpdatedAt(type === "pending_payments" ? undefined : updatedAt);

  const dataWidgetTypesMap = useCollectionDataWidgetTypesMap(i18n);

  const getFormattedMetricValue = () => {
    const textProps: TypographyStyleProps = {
      layout: "block",
      spaceBelow: 1,
      size: 7,
      weight: FontWeight.SemiBold,
    };
    switch (type) {
      case "active_customers": {
        const activeCustomersCount = data?.active_customers?.value ?? 0;

        return (
          <Text {...textProps}>
            {error ? 0 : i18n.number(activeCustomersCount)}
          </Text>
        );
      }
      case "failed_payments": {
        const failedPaymentsCount = data?.failed_payments?.failed_payments ?? 0;
        const totalPaymentsCount = data?.failed_payments?.total_payments ?? 0;

        return (
          <Text {...textProps}>
            {i18n.number(error ? 0 : failedPaymentsCount)}
            <Text size={[5, 4]} lineHeight={5}>
              /{i18n.number(error ? 0 : totalPaymentsCount)}
            </Text>
          </Text>
        );
      }
      case "confirmed_funds":
      case "pending_payments":
      case "pending_payouts": {
        const value = (data?.[type]?.[selectedCurrency]?.value ?? 0) / 100;
        const [currencyValue, fraction] = formatCurrencyAndSplit({
          locale: i18n.locale,
          value: error ? 0 : value,
          separateBy: "decimal",
          options: {
            style: "decimal",
            useGrouping: true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        });

        return (
          <Text {...textProps} css={{ whiteSpace: "nowrap" }}>
            {currencyValue}
            <Text size={[5, 4]} lineHeight={5}>
              {fraction} {selectedCurrency}
            </Text>
          </Text>
        );
      }
    }
  };

  const formattedMetricValue = getFormattedMetricValue();

  const {
    buttonText,
    buttonURLParams,
    name,
    tooltipDescription,
    tooltipId,
    tooltipLabel,
    tooltipLink,
  } = dataWidgetTypesMap[type];

  const tooltipClickEvent = getDataWidgetTooltipEvent(type);
  const buttonClickEvent = getDataWidgetButtonEvent(type);

  return (
    <Box
      bg={Color.White}
      borderColor={[Color.White, ColorPreset.BorderOnLight_04]}
      borderRadius={[0, 1]}
      borderWidth={1}
      flexGrow={1}
      gutterH={[0, 1.5]}
      gutterV={[0, 1.5]}
    >
      <Box layout="flex">
        <H3 preset={TypePreset.Subheading_01} size={[3, 2]} spaceBelow={0.5}>
          {name}
        </H3>
        <Space layout="inline" h={0.5} />
        <Text
          onClick={() =>
            tooltipClickEvent ? sendEvent(tooltipClickEvent) : null
          }
        >
          <Tooltip
            tooltipId={tooltipId}
            message={
              <>
                <P>{tooltipDescription}</P>

                {tooltipLink && (
                  <>
                    <Space v={1.5} />
                    <PlainLink
                      href={tooltipLink}
                      target="_blank"
                      textDecoration="underline"
                      weight={FontWeight.SemiBold}
                    >
                      <Trans id="Learn more">Learn more</Trans>
                    </PlainLink>
                  </>
                )}
              </>
            }
          >
            {tooltipLabel}
          </Tooltip>
        </Text>
      </Box>
      {isLoading ? (
        <Shimmer
          borderRadius={0.5}
          height="24px"
          spaceAbove={0.5}
          spaceBelow={1}
        />
      ) : (
        <>{formattedMetricValue}</>
      )}
      {buttonURLParams && (
        <LinkBuilder
          onClick={() => buttonClickEvent && sendEvent(buttonClickEvent)}
          route={buttonURLParams.route}
          queryParams={buttonURLParams.queryParams}
        >
          {(result) => (
            <PlainLink
              {...result}
              size={2}
              textDecoration="underline"
              weight={FontWeight.SemiBold}
            >
              {buttonText}
            </PlainLink>
          )}
        </LinkBuilder>
      )}
    </Box>
  );
};
