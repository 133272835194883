import {
  Box,
  Glyph,
  H2,
  Link,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useFetchLastActivityEntries } from "../../hooks/useFetchLastActivityEntries";
import { LastActivityType } from "../../../Home/types";
import { Section } from "../../components/Section";
import { HomepageCard } from "../../components/HomepageCard";

import { TopOneOffPayments } from "./TopOneOffPayments";
import { LastCustomers } from "./LastCustomers";
import { TopSubscriptionTemplates } from "./TopSubscriptionTemplates";
import { CardContent } from "./component/CardContent";
import { CustomerLink } from "./component/CustomerLink";
import { ResponsiveWrapper } from "./component/ResponsiveWrapper";

import { CustomerListStatus } from "src/components/routes/Customers/types";
import { TrackingEvent } from "src/common/trackingEvents";
import { Route } from "src/common/routing";

const useItems = () => {
  const { i18n } = useLingui();
  return [
    {
      id: LastActivityType.RecentCustomers,
      listComponent: LastCustomers,
      card: {
        title: i18n._(
          t({
            id: "homepage.quickactions.customers-cardtitle",
            message: "Customers",
          })
        ),
        icon: Glyph.User,
        description: i18n._(
          t({
            id: "homepage.quickactions.customers-description",
            message:
              "Send an invitation via a sharable link, customisable email or CSV import.",
          })
        ),
        buttonComponent: CustomerLink,
        buttonName: i18n._(
          t({
            id: "homepage.quickactions.customers-cardbuttonName",
            message: "Invite",
          })
        ),
        route: Route.CustomersCreate,
        routeParams: { id: "link" },
        event: TrackingEvent.CUSTOMERS_ADD_CUSTOMER_CTA_CLICKED,
      },
      list: {
        title: i18n._(
          t({
            id: "homepage.quickactions.customers-listtitle",
            message: "Recently added",
          })
        ),
        buttonName: i18n._(
          t({
            id: "homepage.quickactions.customers-listbuttonName",
            message: "View all",
          })
        ),
        route: Route.Customers,
        queryParams: { status: CustomerListStatus.ACTIVE },
        event: TrackingEvent.CUSTOMERS_OPEN_LIST_CTA_CLICKED,
        emptyMessage: i18n._(
          t({
            id: "homepage.quickactions.customers-emptyMessage",
            message: "Create your first customer",
          })
        ),
      },
    },
    {
      id: LastActivityType.TopSubscriptionTemplates,
      listComponent: TopSubscriptionTemplates,
      card: {
        title: i18n._(
          t({
            id: "homepage.quickactions.subscriptiontemplates-cardtitle",
            message: "Subscription templates",
          })
        ),
        icon: Glyph.Recurring,
        buttonComponent: Link,
        description: i18n._(
          t({
            id: "homepage.quickactions.subscriptiontemplates-description",
            message:
              "Create templates for your subscriptions to help manage your recurring payments.",
          })
        ),
        buttonName: i18n._(
          t({
            id: "homepage.quickactions.subscriptiontemplates-cardbuttonName",
            message: "Create",
          })
        ),
        route: Route.SubscriptionTemplatesCreate,
        event: TrackingEvent.PLANS_CREATE_PLAN_CTA_CLICKED,
      },
      list: {
        title: i18n._(
          t({
            id: "homepage.quickactions.subscriptiontemplates-listtitle",
            message: "Recent templates",
          })
        ),
        buttonName: i18n._(
          t({
            id: "homepage.quickactions.subscriptiontemplates-listbuttonName",
            message: "View all",
          })
        ),
        route: Route.SubscriptionTemplates,
        event: TrackingEvent.PLANS_OPEN_LIST_CTA_CLICKED,
        emptyMessage: i18n._(
          t({
            id: "homepage.quickactions.subscriptiontemplates-emptyMessage",
            message: "Create your first subscription template",
          })
        ),
      },
    },

    {
      id: LastActivityType.TopOneOffPayments,
      listComponent: TopOneOffPayments,
      card: {
        title: i18n._(
          t({
            id: "homepage.quickactions.paylinks-cardtitle",
            message: "One-off payments",
          })
        ),
        icon: Glyph.One,
        description: i18n._(
          t({
            id: "homepage.quickactions.paylinks-description",
            message:
              "Take a one-off payment from new customers by creating a sharable link requesting funds.",
          })
        ),
        buttonComponent: Link,
        buttonName: i18n._(
          t({
            id: "homepage.quickactions.paylinks-cardbuttonName",
            message: "Create",
          })
        ),
        route: Route.OneOffPaymentCreate,
        event: TrackingEvent.PAYLINKS_CREATE_PAYLINK_CTA_CLICKED,
      },
      list: {
        title: i18n._(
          t({
            id: "homepage.quickactions.paylinks-listtitle",
            message: "Recent one-off payments",
          })
        ),
        buttonName: i18n._(
          t({
            id: "homepage.quickactions.paylinks-listbuttonName",
            message: "View all",
          })
        ),
        route: Route.OneOffPaymentPaylinksList,
        event: TrackingEvent.PAYLINKS_OPEN_LIST_CTA_CLICKED,
        emptyMessage: i18n._(
          t({
            id: "homepage.quickactions.paylinks-emptyMessage",
            message: "Create your first one-off paylink",
          })
        ),
      },
    },
  ];
};

export const QuickActions = () => {
  const items = useItems();
  const { isLoading, entries } = useFetchLastActivityEntries({ sizeOfList: 5 });

  return (
    <Box layout="flex" flexDirection="column">
      <H2
        preset={TypePreset.Heading_04}
        textAlign={TextAlign.Left}
        spaceBelow={2}
      >
        <Trans id="request-payment.quick-actions">Quick actions</Trans>
      </H2>

      <Section>
        {items.map((item) => (
          <HomepageCard key={item.id} noGutterOnMobile>
            <Box width="100%">
              <CardContent {...item.card} />
            </Box>

            <ResponsiveWrapper id={item.id} listTitle={item.list.title}>
              <item.listComponent
                list={item.list}
                isLoading={isLoading}
                entry={entries[item.id]}
              />
            </ResponsiveWrapper>
          </HomepageCard>
        ))}
      </Section>
    </Box>
  );
};
