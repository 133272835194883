import {
  HomeSchemeEnum,
  SchemeIdentifier,
} from "@gocardless/api/dashboard/types";
import { useMemo } from "react";

import { useSchemeIdentifiers } from "../useSchemeIdentifiers";

import { useOrganisation } from "src/queries/organisation";
import { Currency } from "src/common/currencies";

export const IbpGpbScheme = [HomeSchemeEnum.FasterPayments];
export const IbpEurScheme = [
  HomeSchemeEnum.SepaCreditTransfer,
  HomeSchemeEnum.SepaInstantCreditTransfer,
];
export const IbpScheme = [...IbpGpbScheme, ...IbpEurScheme];

export const isSchemeSupported = (
  currency: Currency,
  schemeIdentifiers?: SchemeIdentifier[]
) => {
  const schemeSupported = (scheme: HomeSchemeEnum[]): boolean =>
    !!schemeIdentifiers &&
    schemeIdentifiers.some(
      (schemeIdentifier) =>
        schemeIdentifier.scheme && scheme.includes(schemeIdentifier.scheme)
    );

  if (!schemeSupported(IbpScheme)) {
    return false;
  } else {
    if (currency === Currency.Gbp) {
      return schemeSupported(IbpGpbScheme);
    }
    if (currency === Currency.Eur) {
      return schemeSupported(IbpEurScheme);
    }
    return false;
  }
};

export const useCanIbp = ({
  currency,
  schemeIdentifiers,
}: {
  currency: Currency;
  schemeIdentifiers?: SchemeIdentifier[];
}) => {
  const organisation = useOrganisation();
  const hasOBEnabled = organisation?.has_open_banking_scheme_enabled;
  return useMemo(
    () => !!hasOBEnabled && isSchemeSupported(currency, schemeIdentifiers),
    [currency, hasOBEnabled, schemeIdentifiers]
  );
};

export const useCanIbpForDomesticCurrency = () => {
  const organisation = useOrganisation();
  const domesticCurrency = organisation?.domestic_currency as Currency;
  const hasOBEnabled = organisation?.has_open_banking_scheme_enabled;

  const schemeIdentifiers = useSchemeIdentifiers();
  const canIBP = useMemo(
    () =>
      !!hasOBEnabled && isSchemeSupported(domesticCurrency, schemeIdentifiers),
    [domesticCurrency, hasOBEnabled, schemeIdentifiers]
  );
  return { canIBP };
};
