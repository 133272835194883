import {
  AlignItems,
  Box,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  LinkProps,
  P,
  H3,
  TypePreset,
  ButtonSize,
} from "@gocardless/flux-react";
import { useCallback } from "react";
import { useRouter } from "next-router-mock";

import { Route } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";
import { RouteParams } from "src/common/routing/routes";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export const CardContent: React.FC<{
  icon: Glyph;
  title: string;
  description: string;
  buttonName: string;
  buttonComponent: React.FC<LinkProps>;
  route: Route;
  routeParams?: RouteParams;
  event?: TrackingEvent;
}> = ({
  icon,
  title,
  description,
  buttonName,
  buttonComponent: ButtonComponent,
  route,
  routeParams,
  event,
}) => {
  const { sendEvent } = useSegment();
  const router = useRouter();
  const onClick = useCallback(() => {
    if (event) {
      sendEvent(event as TrackingEvent, {
        page: router.pathname,
      });
    }
  }, [event, router.pathname, sendEvent]);
  return (
    <Box
      gutterH={[1.5, null, 0]}
      gutterV={[1.5, null, 0]}
      width="100%"
      flexGrow={1}
      layout="flex"
      flexDirection="column"
    >
      <Box layout="inline-flex">
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          spaceAfter={0.5}
          bg={Color.Ultraviolet_50}
          gutterV={0.5}
          gutterH={0.5}
          borderRadius={2}
          height="32px"
          width="32px"
        >
          <Icon name={icon} size="14px" />
        </Box>
        <Box gutterV={0.25}>
          <H3 preset={TypePreset.Heading_03}>{title}</H3>
        </Box>
      </Box>
      <P
        css={{ flex: 1 }}
        color={ColorPreset.TextOnLight_02}
        weight={FontWeight.Bold}
        preset={TypePreset.Body_01}
        spaceAbove={1}
        spaceBelow={2}
      >
        {description}
      </P>
      <LinkBuilder route={route} routeParams={routeParams} onClick={onClick}>
        {(result) => (
          <ButtonComponent
            {...result}
            css={{ width: "fit-content" }}
            size={ButtonSize.Sm}
            variant={ButtonVariant.PrimaryOnLight}
          >
            {buttonName}
          </ButtonComponent>
        )}
      </LinkBuilder>
    </Box>
  );
};
