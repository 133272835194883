import {
  Box,
  Icon,
  Glyph,
  ColorPreset,
  Breakpoint,
  Interpose,
  Separator,
} from "@gocardless/flux-react";
import { useMemo } from "react";
import { useCreditorBalanceList } from "@gocardless/api/dashboard/creditor-balance";
import { CreditorBalanceResource } from "@gocardless/api/dashboard/types";

import { HomepageCard } from "../../components/HomepageCard";
import { Section } from "../../components/Section";

import { CreditorBalanceCard } from "./CreditorBalanceCard";

import { CreditorBalancesSingleCurrency } from "src/components/routes/Home/components/CreditorBalancesSingleCurrency";
import { CreditorBalancesMultiCurrency } from "src/components/routes/Home/components/CreditorBalancesMultiCurrency";
import { useOrganisation } from "src/queries/organisation";
import { useDefaultCreditor } from "src/queries/creditor";
import useFetchCurrencyExchangeRateList from "src/common/hooks/useFetchCurrencyExchangeRateList";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";

export const CreditorBalance = () => {
  const { data: creditorBalanceListResponse, isLoading } =
    useCreditorBalanceList();
  const organisation = useOrganisation();
  const creditor = useDefaultCreditor();
  const { currencyExchangeRateList } = useFetchCurrencyExchangeRateList({
    target: organisation?.fx_payout_currency,
  });

  const isMobileOrTabletBreakpoint = useIsBreakpoint({ max: Breakpoint.Md });
  const CreditorBalanceComponent = useMemo(() => {
    const creditorBalances =
      creditorBalanceListResponse?.creditor_balances || [];

    return creditorBalances.length === 1 ? (
      <CreditorBalancesSingleCurrency
        creditorBalance={creditorBalances[0] as CreditorBalanceResource}
      >
        {({ cardConfig }) => (
          <>
            {isMobileOrTabletBreakpoint ? (
              <HomepageCard>
                <Section>
                  <Interpose node={<Separator spacing={0} />}>
                    {cardConfig.map((card) => (
                      <CreditorBalanceCard
                        key={card.title}
                        card={card}
                        creditorBalance={
                          creditorBalances[0] as CreditorBalanceResource
                        }
                      />
                    ))}
                  </Interpose>
                </Section>
              </HomepageCard>
            ) : (
              <Section>
                {cardConfig.map((card) => (
                  <Box height="100%" key={card.title}>
                    <HomepageCard>
                      <CreditorBalanceCard
                        card={card}
                        creditorBalance={
                          creditorBalances[0] as CreditorBalanceResource
                        }
                      />
                    </HomepageCard>
                  </Box>
                ))}
              </Section>
            )}
          </>
        )}
      </CreditorBalancesSingleCurrency>
    ) : (
      <CreditorBalancesMultiCurrency
        creditorBalanceList={creditorBalances}
        creditor={creditor}
        exchangeRates={currencyExchangeRateList}
      />
    );
  }, [
    isMobileOrTabletBreakpoint,
    creditor,
    creditorBalanceListResponse?.creditor_balances,
    currencyExchangeRateList,
  ]);
  return (
    <Box layout="flex" flexDirection="column">
      {isLoading ? (
        <HomepageCard>
          <Icon
            name={Glyph.Spinner}
            color={ColorPreset.IconOnLight_01}
            size="32px"
          />
        </HomepageCard>
      ) : (
        CreditorBalanceComponent
      )}
    </Box>
  );
};
